  
    /* https://stackoverflow.com/questions/28279989/multiple-font-weights-one-font-face-query */



:root {
    --WhiteColour:#FFFFFF;
    --ButtonSuccessBackgroundColour: #4BB543;
    --QuestionButtonClickFlashBackgroundColour:#948699;
    --QuestionButtonSuccessBackgroundColour:#4BB543;
    --QuestionButtonErrorBackgroundColour:#ef4444;
    --QuestionSubmitButtonDisabledColour:#E6E6E6;    
    --Grey400Colour:#9A9A9A; 
    --Grey50Colour:#f4f0ec;
  }
  
  
  @tailwind base;
  @tailwind components;
  @tailwind utilities;
  
  /* // https://developer.apple.com/design/human-interface-guidelines/ios/visual-design/typography/
  /* /* spreadsheet in google drive with formulas */
  
  /* tag level e.g H1, H2 */
  @layer base {
    app-root {  /* <!-- All custom elements are created as inline so you cannot manipulate their dimensions. You need to make them block elements --> */
      display: block;
      height: 100%; 
      width:100%;
      /* postion absolute is needed ca 20240430 */
      position: absolute; 
      top: 0;
      left: 0;
  

    }
  
    
    button{
      @apply cursor-pointer
      /* disabled:cursor-not-allowed disabled:opacity-50 */
    }
  
  
    /* // remove flash when hit div */
    /* div {
      -webkit-tap-highlight-color: rgba(0,0,0,0);
    }
   */



              @font-face {
                font-family: "ABeeZee";
                font-weight: 400;
                src: url(/fonts/ABeeZee-latin-400.woff2) format("woff2");
                font-display: swap;
              }



              @font-face {
                font-family: 'Bubblegum Sans';
                font-weight: 300;
                font-stretch: 0% 200%;
                src: url(/fonts/bubblegumSans-400.woff2) format("woff2");
                font-display: swap;
              }
      
    
  
          /* https://stackoverflow.com/questions/28279989/multiple-font-weights-one-font-face-query */
          @font-face {
            font-family: 'Bubblegum Sans';
            font-weight: 400;
            font-stretch: 0% 200%;
            src: url(/fonts/bubblegumSans-400.woff2) format("woff2");
            font-display: swap;
          }
  
          
    @font-face {
      font-family: 'Bubblegum Sans';
      font-weight: 500;
      font-stretch: 0% 200%;
      src: url(/fonts/bubblegumSans-400.woff2) format("woff2");
      font-display: swap;
    }


  
  
    @font-face {
      font-family: Montserrat;
      font-weight: 100;
      src: url(/fonts/montserrat-v25-latin-100.woff2) format("woff2");
      font-display: swap;
    }
    @font-face {
      font-family: Montserrat;
      font-weight: 200;
      src: url(/fonts/montserrat-v25-latin-200.woff2) format("woff2");
      font-display: swap;
    }
    @font-face {
      font-family: Montserrat;
      font-weight: 300;
      src: url(/fonts/montserrat-v25-latin-300.woff2) format("woff2");
      font-display: swap;
    }
    @font-face {
      font-family: Montserrat;
      font-weight: 400;
      src: url(/fonts/montserrat-v25-latin-regular.woff2) format("woff2");
      font-display: swap;
    }
    @font-face {
      font-family: Montserrat;
      font-weight: 500;
      src: url(/fonts/montserrat-v25-latin-500.woff2) format("woff2");
      font-display: swap;
    }
    @font-face {
      font-family: Montserrat;
      font-weight: 600;
      src: url(/fonts/montserrat-v25-latin-600.woff2) format("woff2");
      font-display: swap;
    }
    @font-face {
      font-family: Montserrat;
      font-weight: 700;
      src: url(/fonts/montserrat-v25-latin-700.woff2) format("woff2");
      font-display: swap;
    }
    @font-face {
      font-family: Montserrat;
      font-weight: 800;
      src: url(/fonts/montserrat-v25-latin-800.woff2) format("woff2");
      font-display: swap;
    }
    @font-face {
      font-family: Montserrat;
      font-weight: 900;
      src: url(/fonts/montserrat-v25-latin-900.woff2) format("woff2");
      font-display: swap;
    }
  
  
    
  }
  
  /* component level e.g. btn-blue */
  @layer components {
  
  
    .button-site-landing {
      @apply
      flex justify-center text-button items-center font-bold px-6  box-border outline-0 whitespace-nowrap  h-9 w-full 
      rounded-[100px]
      text-color1-500 border-color1-500 stroke-color1-500
         border-solid border-[1.5px]
      md:px-8 md:h-10 


  
      active:border-grey-400  active:bg-grey-50 active:text-color1-500
    }

    
    .button-danger {
      @apply
      flex justify-center text-button items-center font-bold px-8  box-border outline-0 whitespace-nowrap h-11 w-full 
      md:px-[2.12rem] md:h-12 
      lg:px-[2.38rem] lg:h-[52px]
      xl:px-[2.62rem] xl:h-14 
      2xl:px-[2.88rem]
      rounded-[100px] 
      border-red-500 border-solid border-2 
      bg-red-500 text-white stroke-white
  
      active:bg-white active:text-red-500

       sm:hover:bg-white sm:hover:text-red-500
      
    }
  
    .button-circle-primary-colour1 {
      @apply
      bg-color1-500 rounded-full  flex items-center justify-center  
      w-10 h-10
      md:w-12 md:h-12    
          active:border-color1-300  active:bg-color1-300
          /* hover:border-color1-300  hover:bg-color1-300 */
    }

    .button-circle-refresh {
      @apply
      absolute bottom-[3vh] right-[3vw]   border border-white
      bg-color3-500 rounded-full  flex items-center justify-center  shadow-lg
      w-10 h-10
      md:w-12 md:h-12    
    }

    .button-lexical-defintion {
      @apply
       bg-link  border border-white rounded-full  flex items-center justify-center text-title3 font-semibold text-white
       shadow-3d-button-shadow-and-gloss
       px-2 py-1
       md:px-4 md:py-2
    }

    .button-undo{
      @apply
      absolute bottom-[3vh] left-[3vw]   border border-white
      rounded-full  flex items-center justify-center  shadow-lg
      w-10 h-10
      md:w-12 md:h-12    
    }


    .button-submit {
      @apply
      flex justify-center text-title1 items-center font-bold px-8 py-5  
      box-border outline-0 whitespace-nowrap h-auto w-auto         
      rounded-[100px] text-white stroke-white bg-color5-400   shadow-3d-button-colour5        
      sm:px-10 sm:py-6 sm:text-large-title 
    }


  
    .button-primary-colour1 {
      @apply
      flex justify-center text-button items-center font-bold px-8  box-border outline-0 whitespace-nowrap h-11 w-full 
        
          rounded-[100px]
          text-white stroke-white
          bg-color1-500 border-color1-500  border-solid border-2 
          md:px-[2.12rem] md:h-12 
          lg:px-[2.38rem] lg:h-[52px]
          xl:px-[2.62rem] xl:h-14 
          2xl:px-[2.88rem] 
  
          active:border-color1-300  active:bg-color1-300
  
    }
  
    .button-outline-colour1 {
      @apply
      flex justify-center text-button items-center font-bold px-8  box-border outline-0 whitespace-nowrap  h-11 w-full 
      rounded-[100px]
      text-color1-500 border-color1-500 stroke-color1-500
       bg-white  border-solid border-2 
      md:px-[2.12rem] md:h-12 
      lg:px-[2.38rem] lg:h-[52px]
      xl:px-[2.62rem] xl:h-14 
      2xl:px-[2.88rem]
  
      active:border-grey-400  active:bg-grey-50 active:text-color1-500
    }

    .svg-button-circle{
      @apply
      h-6	stroke-[3] stroke-white
      md:h-8	
         
    }

    .svg-large-title {
      @apply 
  
      h-[2.06rem] 
      md:h-[2.13rem]	
      lg:h-[2.25rem]	
      xl:h-[2.38rem]	
      2xl:h-[2.5rem]	    
      
      min-w-[2.06rem] 
      md:min-w-[2.13rem]	
      lg:min-w-[2.25rem]	
      xl:min-w-[2.38rem]	
      2xl:min-w-[2.5rem]	
    }
    
    .svg-title1 {
      @apply 
      h-[1.69rem] 
      md:h-[1.75rem]
      lg:h-[1.88rem]
      xl:h-[2rem]
      2xl:h-[2.13rem]       
      
      min-w-[1.69rem] 
      md:min-w-[1.75rem]
      lg:min-w-[1.88rem]
      xl:min-w-[2rem]
      2xl:min-w-[2.13rem]       
  
    }
  
    .svg-title2 {
      @apply 
      h-[1.31rem]	
      md:h-[1.38rem]	
      lg:h-[1.5rem]	
      xl:h-[1.63rem]	
      2xl:h-[1.75rem]	   
      
      min-w-[1.31rem]	
      md:min-w-[1.38rem]	
      lg:min-w-[1.5rem]	
      xl:min-w-[1.63rem]	
      2xl:min-w-[1.75rem]	      
    }
  
  
    .svg-title3 {
      @apply 
      h-[1.19rem]
      md:h-[1.25rem]
      lg:h-[1.38rem]
      xl:h-[1.5rem]
      2xl:h-[1.63rem]      
      
      min-w-[1.19rem]
      md:min-w-[1.25rem]
      lg:min-w-[1.38rem]
      xl:min-w-[1.5rem]
      2xl:min-w-[1.63rem]  
    }
  
    .svg-body {
      @apply 
      h-[1rem] 
      md:h-[1.06rem]
      lg:h-[1.19rem]
      xl:h-[1.31rem]
      2xl:h-[1.44rem]    
      
      min-w-[1rem] 
      md:min-w-[1.06rem]
      lg:min-w-[1.19rem]
      xl:min-w-[1.31rem]
      2xl:min-w-[1.44rem]   
    }
  
    .svg-footnote {
      @apply 
      h-[0.75rem] 
      md:h-[0.81rem]
      lg:h-[0.94rem]
      xl:h-[1.06rem]
      2xl:h-[1.19rem]    
      
      min-w-[0.75rem] 
      md:min-w-[0.81rem]
      lg:min-w-[0.94rem]
      xl:min-w-[1.06rem]
      2xl:min-w-[1.19rem]    
      
  
    }  

    .svg-button-icon {
      @apply 
      h-[1.5rem]	
      md:h-[1.59rem]	
      lg:h-[1.76rem]	
      /* xl:h-[1.97rem]	
      2xl:h-[2.16rem]	      */
      
      min-w-[1.5rem]	
      md:min-w-[1.59rem]	
      lg:min-w-[1.76rem]	
      /* xl:min-w-[1.97rem]	
      2xl:min-w-[2.16rem]	  */
    }
  
    .loader-button-primary-colour1 {
      @apply 
      border-grey-300 rounded-full border-[3px] border-t-white
      mr-3 
      animate-spin
      h-[1.2rem] w-[1.2rem] 
      md:h-[1.27rem] md:w-[1.27rem]
      lg:h-[1.43rem] lg:w-[1.43rem]
      /* xl:h-[1.57rem] xl:w-[1.57rem]
      2xl:h-[1.73rem] 2xl:w-[1.73rem] */
                             
    }

    .text-single-sight-symbol {
      @apply 
      
      mx-[5px] my-[5px] text-[70px]	leading-[70px]	 px-2 py-1 w-[84px] h-[126px]          
      sm:text-[85px]	sm:leading-[85px]	 sm:px-3 sm:py-2 sm:w-[100px] sm:h-[150px]  
      md:text-[90px]	md:leading-[90px]	 md:px-3 md:py-2 md:w-[108px] md:h-[164px]  

    }

    .text-4xlarge-title {
      @apply 
      text-[3rem]	leading-[3.64rem]	
      md:text-[3.10rem]	md:leading-[3.77rem]	
      lg:text-[3.27rem]	lg:leading-[3.98rem]	
      /* xl:text-[2.86rem]	xl:leading-[3.46rem]	
      2xl:text-[3.0rem]	2xl:leading-[3.6rem]	       */
    }

      .text-3xlarge-title {
        @apply 
        text-[2.47rem]	leading-[3.0rem]	
        md:text-[2.56rem]	md:leading-[3.07rem]	
        lg:text-[2.70rem]	lg:leading-[3.23rem]	
        /* xl:text-[2.86rem]	xl:leading-[3.46rem]	
        2xl:text-[3.0rem]	2xl:leading-[3.6rem]	       */
      }
  
      .text-2xlarge-title {
        @apply 
        text-[2.27rem]	leading-[2.75rem]	 
        md:text-[2.34rem]	md:leading-[2.82rem]	
        lg:text-[2.48rem]	lg:leading-[2.96rem]	
        /* xl:text-[2.62rem]	xl:leading-[3.17rem]	
        2xl:text-[2.75rem]	2xl:leading-[3.3rem]	       */
      
      }
  
      .text-large-title {
        @apply 
        text-[2.06rem]	leading-[2.5rem]	 
        md:text-[2.13rem]	md:leading-[2.56rem]	
        lg:text-[2.25rem]	lg:leading-[2.69rem]	
        /* xl:text-[2.38rem]	xl:leading-[2.88rem]	
        2xl:text-[2.5rem]	2xl:leading-[3rem]	       */
      }
  
      .text-title1 {
        @apply 
        text-[1.69rem]	leading-[2.06rem] 
        md:text-[1.75rem]	md:leading-[2.13rem]	
        lg:text-[1.88rem]	lg:leading-[2.31rem]	
        /* xl:text-[2rem]	xl:leading-[2.44rem]	
        2xl:text-[2.13rem]	2xl:leading-[2.56rem]	            */
      }

      .text-title2-5 {
        @apply 
        text-[1.5rem]	leading-[2.8rem] 
        /* md:text-[1.75rem]	md:leading-[2.13rem]	
        lg:text-[1.88rem]	lg:leading-[2.31rem]	 */
        /* xl:text-[2rem]	xl:leading-[2.44rem]	
        2xl:text-[2.13rem]	2xl:leading-[2.56rem]	            */
      }

      .text-title2 {
        @apply 
        text-[1.31rem]	leading-[1.63rem]	 
        md:text-[1.38rem]	md:leading-[1.75rem]	
        lg:text-[1.5rem]	lg:leading-[1.88rem]	
        /* xl:text-[1.63rem]	xl:leading-[2rem]	
        2xl:text-[1.75rem]	2xl:leading-[2.13rem]	                */
      }

      .text-title3 {
        @apply 
        text-[1.19rem]	leading-[1.5rem]	
        md:text-[1.25rem]	md:leading-[1.56rem]	
        lg:text-[1.38rem]	lg:leading-[1.75rem]	
        /* xl:text-[1.5rem]	xl:leading-[1.88rem]	
        2xl:text-[1.63rem]	2xl:leading-[2rem]	                   */
      }
  
      .text-body {
        @apply 
        text-[1rem]	leading-[1.31rem]	 
        md:text-[1.06rem]	md:leading-[1.38rem]	
        lg:text-[1.19rem]	lg:leading-[1.5rem]	
        /* xl:text-[1.31rem]	xl:leading-[1.63rem]	
        2xl:text-[1.44rem]	2xl:leading-[1.81rem]	                             */
      }

      .text-callout {
        @apply 
        text-[0.94rem]	leading-[1.25rem]	 
        md:text-[1rem]	md:leading-[1.31rem]	
        lg:text-[1.13rem]	lg:leading-[1.44rem]	
        /* xl:text-[1.25rem]	xl:leading-[1.56rem]	
        2xl:text-[1.38rem]	2xl:leading-[1.75rem]	                                  */
      }

      .text-subhead {
        @apply 
        text-[0.88rem]	leading-[1.19rem]	 
        md:text-[0.94rem]	md:leading-[1.25rem]	
        lg:text-[1.06rem]	lg:leading-[1.38rem]	
        /* xl:text-[1.19rem]	xl:leading-[1.5rem]	
        2xl:text-[1.31rem]	2xl:leading-[1.75rem]	                                       */
      }

      .text-footnote {
        @apply 
        text-[0.75rem]	leading-[1rem]	 
        md:text-[0.81rem]	md:leading-[1.13rem]	
        lg:text-[0.94rem]	lg:leading-[1.25rem]	
        /* xl:text-[1.06rem]	xl:leading-[1.38rem]	
        2xl:text-[1.19rem]	2xl:leading-[1.5rem]	                                            */
      }

      .text-caption1 {
        @apply  
        text-[0.69rem]	leading-[0.81rem]	 
        md:text-[0.75rem]	md:leading-[1rem]	
        lg:text-[0.88rem]	lg:leading-[1.19rem]	
        /* xl:text-[1rem]	xl:leading-[1.31rem]	
        2xl:text-[1.13rem]	2xl:leading-[1.44rem]	                                                  */
      }

      .text-caption2 {
        @apply 
        text-[0.69rem]	leading-[0.81rem]	 
        md:text-[0.69rem]	md:leading-[0.81rem]	
        lg:text-[0.81rem]	lg:leading-[1.13rem]	
        /* xl:text-[0.94rem]	xl:leading-[1.25rem]	
        2xl:text-[1.06rem]	2xl:leading-[1.38rem]	                                                      */
      }

      .text-button {
        @apply 
        text-[1rem]	leading-[1rem]	
        md:text-[1.06rem]	md:leading-[1.06rem]	
        lg:text-[1.19rem]	lg:leading-[1.19rem]	
        /* xl:text-[1.31rem]	xl:leading-[1.31rem]	
        2xl:text-[1.44rem]	2xl:leading-[1.44rem]	                             */
      }
  
      .modal-overlay {
        @apply fixed inset-0 bg-grey-900 bg-opacity-50 overflow-y-auto h-full w-full z-[9998]
   
      }
  
      .modal-content {
      @apply fixed top-[10vh] mx-auto p-5 border border-grey-500 w-[90%] left-[5%] shadow-lg rounded-3xl bg-white z-[9999]
      sm:max-w-[600px] sm:left-[calc((100vw-600px)/2)]
      }
      
      .mobile-menu-heading {
        @apply  text-title1 font-normal   ml-4 whitespace-nowrap 
      }
  
      .card-divider{
        @apply border-t-[1px] border-grey-100 my-4
      }

      .card-title-large {
        @apply text-title1 text-color1-500 font-semibold mb-2
      }
  
      .card-title {
        @apply text-title2 text-color1-500 font-semibold mb-2
      }
  
      .card-title-no-content {
        @apply text-title2 text-color1-500 font-semibold 
      }
  
      .icon-card-title-large {
        @apply stroke-color1-500 stroke-1 mr-3 
        h-[2.5rem]	
      }
  
      .icon-card-title {
        @apply svg-title2 stroke-color1-500 stroke-2 mr-2 
      }
      
      .card-body {
        @apply text-body text-color1-500 mt-3 
      }

      .card {
        @apply w-full pt-4  pb-4 px-4  bg-white rounded-2xl  shadow-md mt-5 min-h-[120px] min-w-[340px] h-auto
        sm:min-h-[100px] 
         md:max-w-[min((47%),800px)] md:ml-4 md:mt-4  md:min-h-[120px]   
      }
  
      .card-parent{
        @apply lg:ml-[-16px]
        md:flex md:flex-row md:flex-wrap 
      }

      .card-single{
        @apply max-w-[900px]
      }
  
      .form-width-max{
        @apply max-w-[600px]
      }
 
  }
  
  /* class level e.g. filter-none */
  @layer utilities {
  
      input[type=checkbox] {
        @apply accent-blueinput
        h-[1rem] min-w-[1rem]
        md:h-[1.06rem]
        lg:h-[1.19rem]
        xl:h-[1.31rem]
        2xl:h-[1.44rem]       
        }
  
  
    .selectHasSelection{
      box-shadow:  0 0 0px 9999px rgb(232, 240, 254) inset !important;
      background-color: rgb(232, 240, 254) !important;
      }

    input:not(:placeholder-shown):not([type=checkbox]):not([type=range]):not([type=date]) {
      box-shadow:  0 0 0px 9999px rgb(232, 240, 254) inset !important;
      background-color: rgb(232, 240, 254) !important;
      background-clip: content-box !important;
      }
  
      input:not([type=checkbox]):not([type=range]) {@apply 
        bg-white 
        border-color1-500 
        text-grey-900 
        rounded-lg 
        block 
        w-full 
        p-2.5 
        border
      }

    .avatar-height-width {@apply 
      w-[64px] h-[64px]
      lg:w-[72px] lg:h-[72px] 
      2xl:w-[80px] 2xl:h-[80px]
    }
  
      .avatar-time-last-seen-string {@apply 
        bottom-0 right-0 border-white border-2 rounded-2xl absolute z-50   flex justify-center items-center text-white bg-orange
        w-[34px] h-[17px] text-[65%] leading-[65%] pt-[3%]
        lg:w-[38px] lg:h-[19px] lg:text-[70%] lg:leading-[70%] lg:pt-0
        /* 2xl:w-[43px] 2xl:h-[22px] 2xl:text-[80%] 2xl:leading-[80%] */
      }
  
      .avatar-time-last-now {@apply 
        stroke-white stroke-[10] fill-green absolute z-40 bottom-[0]
        h-[20px] w-[20px]  right-[7px]
        lg:h-[23px] lg:w-[23px]  lg:right-[8px]
        2xl:h-[25px] 2xl:w-[25px]  2xl:right-[9px]
      }
    
      .avatar-task-just-completed {@apply 
      absolute z-40 bottom-[0] fill-color5-100
        h-[20px] w-[20px]  right-[7px]
        lg:h-[23px] lg:w-[23px]  lg:right-[8px]
        2xl:h-[25px] 2xl:w-[25px]  2xl:right-[9px]
      }

      /* shadow-[0_0_15px_#CDCDCD]    */
      .selectionOption{
        @apply
        touch-manipulation font-normal flex items-center justify-center rounded-lg  bg-white 
        border-[0.5px] border-grey-100   shadow-selection-option-small
        text-grey-600
      }

      .selectionOptionLarge{
        @apply
        touch-manipulation font-normal flex items-center justify-center rounded-lg  bg-white 
        border-[0.5px] border-grey-100   shadow-selection-option-large
        text-grey-600
      }

  
    .max-width-app {max-width: 1540px;}

    .max-width-app-child {max-width: 800px;}
  
  .hide-scroll-bar::-webkit-scrollbar {
    display: none;
  }
  
    .fade-in {
      opacity: 1;
      animation-name: fadeInOpacity;
      animation-iteration-count: 1;
      animation-timing-function: ease-in;
      animation-duration: 1s;
    }
  
    .fade-in-1-2 {
      opacity: 1;
      animation-name: fadeInOpacity;
      animation-iteration-count: 1;
      animation-timing-function: ease-in;
      animation-duration: 0.5s;
    }
  
  
    @keyframes fadeInOpacity {
      0% {
        opacity: 0;
      }
  
      100% {
        opacity: 1;
      }
    }
  
    .fade-out {
      opacity: 0;
      animation-name: fadeOutOpacity;
      animation-iteration-count: 1;
      animation-timing-function: ease-in;
      animation-duration: 0.5s;
    }
  
    @keyframes fadeOutOpacity {
      0% {
        opacity: 1;
      }
  
      100% {
        opacity: 0;
      }
    }

  
  @keyframes move-up-down {
    0%, 100% {
      top: 70%;
    }
    50% {
      top: 110%;
    }
  }
    
  
 .font-for-learning{
    font-family: "ABeeZee";
  font-weight: 400;
  }

    
 .font-for-learning-300{
  font-family: "ABeeZee";
font-weight: 300;
}

  .glass::after{
    content: '';
    position: absolute;
    top:0;
    left:0;    
    width: 100%;
    border-radius: 9999px;
    height: 30%;
    background: linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.2));
  }

  .glassCircleSmall:after{
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    left:0%;
    border-radius: 9999px;
    /* box-shadow:0 10px white inset; */
    box-shadow: 0 0px 0 1px white inset, 0 7px rgba(255, 255, 255, 0.25) inset;

  }

  .glassAudioKnob:after{
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    left:0%;
    border-radius: 9999px;

box-shadow: 0 0 0 1px #FFA500 inset, 0 5px 5px rgba(3, 25, 41, 0.17), 0 10px rgba(255, 255, 255, 0.25) inset;

  }
  
  }
  